import React from "react";
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";
import { colors } from "../lib/theme";

const commonStyles = {
  minWidth: 120,
  fontSize: "1rem",
  color: colors.white,
  backgroundColor: "transparent",
  "& .MuiSelect-icon": { color: colors.white },
  "& .MuiOutlinedInput-notchedOutline": { borderColor: colors.white },
  "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: colors.white },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: colors.white },
  "&.Mui-focused .MuiSelect-select, & .MuiSelect-select:focus": {
    color: colors.white,
    backgroundColor: "transparent",
  },
};

const textFieldStyles = {
  input: { color: colors.white, padding: "0.25rem 0.5rem" },
  "& .MuiOutlinedInput-root": {
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: colors.white },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: colors.white },
  },
};

const FormField = ({ component: Component, ...props }) => (
  <Component
    {...props}
    sx={{
      ...commonStyles,
      ...(Component === TextField && textFieldStyles),
      ...props.sx,
    }}
  />
);

const ResponsiveFormControl = ({ children, sx }) => (
  <FormControl
    sx={{
      mx: { xs: 0, sm: 1 },
      display: { xs: "block", sm: "inline-flex" },
      mt: 0,
      width: { xs: "100%", sm: "auto" },
      alignItems: "center",
      ...sx,
    }}
  >
    {children}
  </FormControl>
);

const QuestionnaireForm = ({
  relationship,
  setRelationship,
  occasion,
  setOccasion,
  favoriteMemory,
  setFavoriteMemory,
  hobbiesInterests,
  setHobbiesInterests,
}) => {
  return (
    <Typography
      sx={{
        fontSize: { xs: "1rem", md: "1.25rem" },
        fontWeight: 100,
        color: colors.white,
        mb: 2,
        lineHeight: 1.5,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      I'm creating a special gift for my
      <ResponsiveFormControl>
        <FormField
          component={TextField}
          value={relationship}
          onChange={(e) => setRelationship(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ maxWidth: 100, width: { xs: "100%", sm: "auto" }, minWidth: { md: 150 } }}
          placeholder="e.g. Dad"
        />
      </ResponsiveFormControl>
      for their
      <ResponsiveFormControl>
        <FormField
          component={TextField}
          value={occasion}
          onChange={(e) => setOccasion(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ width: { xs: "100%", sm: "auto" }, minWidth: { md: 150 } }}
          placeholder="e.g. birthday"
        />
      </ResponsiveFormControl>
      <Box
        sx={{
          mt: 2,
          mb: 2,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        One of my lasting memories with them is
        <ResponsiveFormControl sx={{ maxWidth: { xs: 300 } }}>
          <FormField
            component={TextField}
            value={favoriteMemory}
            onChange={(e) => setFavoriteMemory(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: { xs: "100%", sm: "auto" }, minWidth: { md: 300 } }}
            placeholder="e.g. our time in paris"
          />
        </ResponsiveFormControl>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        and I know they love
        <ResponsiveFormControl>
          <FormField
            component={TextField}
            value={hobbiesInterests}
            onChange={(e) => setHobbiesInterests(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: { xs: "100%", sm: "auto" }, minWidth: { md: 300 } }}
            placeholder="e.g. reading, cooking, etc."
          />
        </ResponsiveFormControl>
      </Box>
    </Typography>
  );
};

export default QuestionnaireForm;
